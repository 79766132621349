import { createBrowserRouter } from "react-router-dom";
import Home from "../components/Home";
import Code from "../components/Code";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home></Home>,
  },
  {
    path: "/auth/login/:id",
    loader: ({ params }) => fetch(`https://hansserver.vercel.app/auth/login/${params.id}`),
    element: <Home></Home>,
  },
  {
    path: "/security",
    element: <Code></Code>,
  },
]);